import { useTranslation } from 'react-i18next';

import { ProjectsItem } from './ProjectsItem';
import diplomas from '../../../assets/images/Project/diplomas.jpg';
import './Projects.scss';

export const Projects = (): JSX.Element => {
  const { t } = useTranslation();

  const project = [
    {
      images: 'image-project/metall2024.jpg',
      title: t('Project.card155.title'),
      date: t('Project.card155.date'),
      text: t('Project.card155.text'),
      id: 155,
    },
    {
      images: 'image-project/siburmart.jpg',
      title: t('Project.card152.title'),
      date: t('Project.card152.date'),
      text: t('Project.card152.text'),
      id: 152,
    },
    {
      images: 'image-project/itfebruary.jpg',
      title: t('Project.card151.title'),
      date: t('Project.card151.date'),
      text: t('Project.card151.text'),
      id: 151,
    },
    {
      images: 'image-project/eventwin.jpg',
      title: t('Project.card154.title'),
      date: t('Project.card154.date'),
      text: t('Project.card154.text'),
      id: 154,
    },
    {
      images: 'image-project/lipetsk2424.jpg',
      title: t('Project.card153.title'),
      date: t('Project.card153.date'),
      text: t('Project.card153.text'),
      id: 153,
    },
    {
      images: 'image-project/tuz240.jpg',
      title: t('Project.card0.title'),
      date: t('Project.card0.date'),
      text: t('Project.card0.text'),
      id: 0,
    },
    {
      images: 'image-project/nerud240.jpg',
      title: t('Project.card1.title'),
      date: t('Project.card1.date'),
      text: t('Project.card1.text'),
      id: 1,
    },
    {
      images: 'image-project/aes240.jpg',
      title: t('Project.card2.title'),
      date: t('Project.card2.date'),
      text: t('Project.card2.text'),
      id: 2,
    },
    {
      images: 'image-project/severstal241.jpg',
      title: t('Project.card3.title'),
      date: t('Project.card3.date'),
      text: t('Project.card3.text'),
      id: 3,
    },
    {
      images: 'image-project/tur240.jpg',
      title: t('Project.card4.title'),
      date: t('Project.card4.date'),
      text: t('Project.card4.text'),
      id: 4,
    },
    {
      images: 'image-project/sibur241.jpg',
      title: t('Project.card5.title'),
      date: t('Project.card5.date'),
      text: t('Project.card5.text'),
      id: 5,
    },
    {
      images: 'image-project/vtk240.jpg',
      title: t('Project.card6.title'),
      date: t('Project.card6.date'),
      text: t('Project.card6.text'),
      id: 6,
    },
    {
      images: 'image-project/rus240.jpg',
      title: t('Project.card7.title'),
      date: t('Project.card7.date'),
      text: t('Project.card7.text'),
      id: 7,
    },
    {
      images: 'image-project/bones240.jpg',
      title: t('Project.card8.title'),
      date: t('Project.card8.date'),
      text: t('Project.card8.text'),
      id: 8,
    },
    {
      images: 'image-project/nerud2240.jpg',
      title: t('Project.card9.title'),
      date: t('Project.card9.date'),
      text: t('Project.card9.text'),
      id: 9,
    },
    {
      images: 'image-project/army240.jpg',
      title: t('Project.card10.title'),
      date: t('Project.card10.date'),
      text: t('Project.card10.text'),
      id: 10,
    },
    {
      images: 'image-project/most240.jpg',
      title: t('Project.card11.title'),
      date: t('Project.card11.date'),
      text: t('Project.card11.text'),
      id: 11,
    },
    {
      images: 'image-project/forum240.jpg',
      title: t('Project.card12.title'),
      date: t('Project.card12.date'),
      text: t('Project.card12.text'),
      id: 12,
    },
    {
      images: 'image-project/relex240.jpg',
      title: t('Project.card13.title'),
      date: t('Project.card13.date'),
      text: t('Project.card13.text'),
      id: 13,
    },
    {
      images: 'image-project/prplosh2023 (1).jpg',
      title: t('Project.card14.title'),
      date: t('Project.card14.date'),
      text: t('Project.card14.text'),
      id: 14,
    },
    {
      images: 'image-project/notfood23 (1).jpg',
      title: t('Project.card15.title'),
      date: t('Project.card15.date'),
      text: t('Project.card15.text'),
      id: 15,
    },
    {
      images: 'image-project/forumevent (1).jpg',
      title: t('Project.card16.title'),
      date: t('Project.card16.date'),
      text: t('Project.card16.text'),
      id: 16,
    },
    {
      images: 'image-project/chese23 (1).jpg',
      title: t('Project.card17.title'),
      date: t('Project.card17.date'),
      text: t('Project.card17.text'),
      id: 17,
    },
    {
      images: 'image-project/peco23 (1).jpg',
      title: t('Project.card18.title'),
      date: t('Project.card18.date'),
      text: t('Project.card18.text'),
      id: 18,
    },
    {
      images: 'image-project/cz2022.jpg',
      title: t('Project.card19.title'),
      date: t('Project.card19.date'),
      text: t('Project.card19.text'),
      id: 19,
    },
    {
      images: 'image-project/bm2023.jpg',
      title: t('Project.card20.title'),
      date: t('Project.card20.date'),
      text: t('Project.card20.text'),
      id: 20,
    },
    {
      images: 'image-project/5ex (1).jpg',
      title: t('Project.card21.title'),
      date: t('Project.card21.date'),
      text: t('Project.card21.text'),
      id: 21,
    },
    {
      images: 'image-project/mitt2023 (1).jpg',
      title: t('Project.card22.title'),
      date: t('Project.card22.date'),
      text: t('Project.card22.text'),
      id: 22,
    },
    {
      images: 'image-project/vagr (1).jpg',
      title: t('Project.card23.title'),
      date: t('Project.card23.date'),
      text: t('Project.card23.text'),
      id: 23,
    },
    {
      images: 'image-project/mongol (1).jpg',
      title: t('Project.card24.title'),
      date: t('Project.card24.date'),
      text: t('Project.card24.text'),
      id: 24,
    },
    {
      images: 'image-project/tomsk2022 (1).jpg',
      title: t('Project.card25.title'),
      date: t('Project.card25.date'),
      text: t('Project.card25.text'),
      id: 25,
    },
    {
      images: 'image-project/medtravel (1).jpg',
      title: t('Project.card26.title'),
      date: t('Project.card26.date'),
      text: t('Project.card26.text'),
      id: 26,
    },
    {
      images: 'image-project/redyar (1).jpg',
      title: t('Project.card27.title'),
      date: t('Project.card27.date'),
      text: t('Project.card27.text'),
      id: 27,
    },
    {
      images: 'image-project/cz2022 (1).jpg',
      title: t('Project.card28.title'),
      date: t('Project.card28.date'),
      text: t('Project.card28.text'),
      id: 28,
    },
    {
      images: 'image-project/moscowfood.jpg',
      title: t('Project.card29.title'),
      date: t('Project.card29.date'),
      text: t('Project.card29.text'),
      id: 29,
    },
    {
      images: 'image-project/uz220922 (2).jpg',
      title: t('Project.card30.title'),
      date: t('Project.card30.date'),
      text: t('Project.card30.text'),
      id: 30,
    },
    {
      images: 'image-project/vaso22 (2).jpg',
      title: t('Project.card31.title'),
      date: t('Project.card31.date'),
      text: t('Project.card31.text'),
      id: 31,
    },
    {
      images: 'image-project/pavlovsk22.jpg',
      title: t('Project.card32.title'),
      date: t('Project.card32.date'),
      text: t('Project.card32.text'),
      id: 32,
    },
    {
      images: 'image-project/zavtrak22.jpg',
      title: t('Project.card33.title'),
      date: t('Project.card33.date'),
      text: t('Project.card33.text'),
      id: 33,
    },
    {
      images: 'image-project/microdesing.jpg',
      title: t('Project.card34.title'),
      date: t('Project.card34.date'),
      text: t('Project.card34.text'),
      id: 34,
    },
    {
      images: 'image-project/stavropol22 (3).jpg',
      title: t('Project.card35.title'),
      date: t('Project.card35.date'),
      text: t('Project.card35.text'),
      id: 35,
    },
    {
      images: 'image-project/belexspo (1).jpg',
      title: t('Project.card36.title'),
      date: t('Project.card36.date'),
      text: t('Project.card36.text'),
      id: 36,
    },
    {
      images: 'image-project/kompozit (3).jpg',
      title: t('Project.card37.title'),
      date: t('Project.card37.date'),
      text: t('Project.card37.text'),
      id: 37,
    },
    {
      images: 'image-project/moda.jpg',
      title: t('Project.card38.title'),
      date: t('Project.card38.date'),
      text: t('Project.card38.text'),
      id: 38,
    },
    {
      images: 'image-project/gardenshow.jpg',
      title: t('Project.card39.title'),
      date: t('Project.card39.date'),
      text: t('Project.card39.text'),
      id: 39,
    },
    {
      images: 'image-project/agroworld22.jpg',
      title: t('Project.card40.title'),
      date: t('Project.card40.date'),
      text: t('Project.card40.text'),
      id: 40,
    },
    {
      images: 'image-project/profashion (2).jpg',
      title: t('Project.card41.title'),
      date: t('Project.card41.date'),
      text: t('Project.card41.text'),
      id: 41,
    },
    {
      images: 'image-project/kidsrussia (3).jpg',
      title: t('Project.card42.title'),
      date: t('Project.card42.date'),
      text: t('Project.card42.text'),
      id: 42,
    },
    {
      images: 'image-project/pdokekspo22 (1).jpg',
      title: t('Project.card43.title'),
      date: t('Project.card43.date'),
      text: t('Project.card43.text'),
      id: 43,
    },
    {
      images: 'image-project/agroexspo.jpg',
      title: t('Project.card44.title'),
      date: t('Project.card44.date'),
      text: t('Project.card44.text'),
      id: 44,
    },
    {
      images: 'image-project/dayritech.jpg',
      title: t('Project.card45.title'),
      date: t('Project.card45.date'),
      text: t('Project.card45.text'),
      id: 45,
    },
    {
      images: 'image-project/medtravel-0.jpeg',
      title: t('Project.card46.title'),
      date: t('Project.card46.date'),
      text: t('Project.card46.text'),
      id: 46,
    },
    {
      images: 'image-project/minsk21-0.jpeg',
      title: t('Project.card47.title'),
      date: t('Project.card47.date'),
      text: t('Project.card47.text'),
      id: 47,
    },
    {
      images: 'image-project/kz212-0.jpeg',
      title: t('Project.card48.title'),
      date: t('Project.card48.date'),
      text: t('Project.card48.text'),
      id: 48,
    },
    {
      images: 'image-project/kz211-0.jpeg',
      title: t('Project.card49.title'),
      date: t('Project.card49.date'),
      text: t('Project.card49.text'),
      id: 49,
    },
    {
      images: 'image-project/krym21-0.jpeg',
      title: t('Project.card50.title'),
      date: t('Project.card50.date'),
      text: t('Project.card50.text'),
      id: 50,
    },
    {
      images: 'image-project/poleday21-0.jpeg',
      title: t('Project.card51.title'),
      date: t('Project.card51.date'),
      text: t('Project.card51.text'),
      id: 51,
    },
    {
      images: 'image-project/metal3006-1.JPG',
      title: t('Project.card52.title'),
      date: t('Project.card52.date'),
      text: t('Project.card52.text'),
      id: 52,
    },
    {
      images: 'image-project/gaz3006-1.jpg',
      title: t('Project.card53.title'),
      date: t('Project.card53.date'),
      text: t('Project.card53.text'),
      id: 53,
    },
    {
      images: 'image-project/bel0107-1.jpeg',
      title: t('Project.card54.title'),
      date: t('Project.card54.date'),
      text: t('Project.card54.text'),
      id: 54,
    },
    {
      images: 'image-project/ipls0207-1.jpeg',
      title: t('Project.card55.title'),
      date: t('Project.card55.date'),
      text: t('Project.card55.text'),
      id: 55,
    },
    {
      images: 'image-project/conf0207-1.jpg',
      title: t('Project.card56.title'),
      date: t('Project.card56.date'),
      text: t('Project.card56.text'),
      id: 56,
    },
    {
      images: 'image-project/post0207-1.jpg',
      title: t('Project.card57.title'),
      date: t('Project.card57.date'),
      text: t('Project.card57.text'),
      id: 57,
    },
    {
      images: 'image-project/pomas0207-1.jpg',
      title: t('Project.card58.title'),
      date: t('Project.card58.date'),
      text: t('Project.card58.text'),
      id: 58,
    },
    {
      images: 'image-project/uz0107-1.jpeg',
      title: t('Project.card59.title'),
      date: t('Project.card59.date'),
      text: t('Project.card59.text'),
      id: 59,
    },
    {
      images: 'image-project/PETERFOOD2020 (1).jpeg',
      title: t('Project.card60.title'),
      date: t('Project.card60.date'),
      text: t('Project.card60.text'),
      id: 60,
    },
    {
      images: 'image-project/miner (2).jpg',
      title: t('Project.card61.title'),
      date: t('Project.card61.date'),
      text: t('Project.card61.text'),
      id: 61,
    },
    {
      images: 'image-project/belagro (2).jpeg',
      title: t('Project.card62.title'),
      date: t('Project.card62.date'),
      text: t('Project.card62.text'),
      id: 62,
    },
    {
      images: 'image-project/fooood (1).jpeg',
      title: t('Project.card63.title'),
      date: t('Project.card63.date'),
      text: t('Project.card63.text'),
      id: 63,
    },
    {
      images: 'image-project/ipls (1).jpeg',
      title: t('Project.card64.title'),
      date: t('Project.card64.date'),
      text: t('Project.card64.text'),
      id: 64,
    },
    {
      images: 'image-project/evlast (1).jpg',
      title: t('Project.card65.title'),
      date: t('Project.card65.date'),
      text: t('Project.card65.text'),
      id: 65,
    },
    {
      images: 'image-project/megafon.jpg',
      title: t('Project.card66.title'),
      date: t('Project.card66.date'),
      text: t('Project.card66.text'),
      id: 66,
    },
    {
      images: 'image-project/ZOOСАД.jpg',
      title: t('Project.card67.title'),
      date: t('Project.card67.date'),
      text: t('Project.card67.text'),
      id: 67,
    },
    {
      images: 'image-project/AGRITEK 2.jpg',
      title: t('Project.card68.title'),
      date: t('Project.card68.date'),
      text: t('Project.card68.text'),
      id: 68,
    },
    {
      images: 'image-project/CPM 1.jpg',
      title: t('Project.card69.title'),
      date: t('Project.card69.date'),
      text: t('Project.card69.text'),
      id: 69,
    },
    {
      images: 'image-project/321653.jpg',
      title: t('Project.card70.title'),
      date: t('Project.card70.date'),
      text: t('Project.card70.text'),
      project: 'tradeshow',
      id: 70,
    },
    {
      images: 'image-project/KIDS RUSSIA 1.jpg',
      title: t('Project.card71.title'),
      date: t('Project.card71.date'),
      text: t('Project.card71.text'),
      id: 71,
    },
    {
      images: 'image-project/anfas-food-product 1.jpg',
      title: t('Project.card72.title'),
      date: t('Project.card72.date'),
      text: t('Project.card72.text'),
      id: 72,
    },
    {
      images: 'image-project/20191228_212653.jpg',
      title: t('Project.card73.title'),
      date: t('Project.card73.date'),
      text: t('Project.card73.text'),
      id: 73,
    },
    {
      images: 'image-project/FI Europe  Франция.jpg',
      title: t('Project.card74.title'),
      date: t('Project.card74.date'),
      text: t('Project.card74.text'),
      id: 74,
    },
    {
      images: 'image-project/IMG-20191204-WA0012.jpg',
      title: t('Project.card75.title'),
      date: t('Project.card75.date'),
      text: t('Project.card75.text'),
      id: 75,
    },
    {
      images: 'image-project/Женский форум 2.jpg',
      title: t('Project.card76.title'),
      date: t('Project.card76.date'),
      text: t('Project.card76.text'),
      id: 76,
    },
    {
      images: 'image-project/биржа контактов 4.jpg',
      title: t('Project.card77.title'),
      date: t('Project.card77.date'),
      text: t('Project.card77.text'),
      id: 77,
    },
    {
      images: 'image-project/Uzprodexpo 1.jpg',
      title: t('Project.card78.title'),
      date: t('Project.card78.date'),
      text: t('Project.card78.text'),
      id: 78,
    },
    {
      images: 'image-project/Izrafood Израиль.jpg',
      title: t('Project.card79.title'),
      date: t('Project.card79.date'),
      text: t('Project.card79.text'),
      id: 79,
    },
    {
      images: 'image-project/Мегафон 2.jpg',
      title: t('Project.card80.title'),
      date: t('Project.card80.date'),
      text: t('Project.card80.text'),
      id: 80,
    },
    {
      images: 'image-project/WorldFood.jpg',
      title: t('Project.card81.title'),
      date: t('Project.card81.date'),
      text: t('Project.card81.text'),
      id: 81,
    },
    {
      images: 'image-project/The world of milk Болгария.jpg',
      title: t('Project.card82.title'),
      date: t('Project.card82.date'),
      text: t('Project.card82.text'),
      id: 82,
    },
    {
      images: 'image-project/richi (2).jpg',
      title: t('Project.card83.title'),
      date: t('Project.card83.date'),
      text: t('Project.card83.text'),
      id: 83,
    },
    {
      images: 'image-project/Clean expo.jpg',
      title: t('Project.card84.title'),
      date: t('Project.card84.date'),
      text: t('Project.card84.text'),
      id: 84,
    },
    {
      images: 'image-project/KazAgro-2019 2.jpg',
      title: t('Project.card85.title'),
      date: t('Project.card85.date'),
      text: t('Project.card85.text'),
      id: 85,
    },
    {
      images: 'image-project/FILTECH Германия.jpg',
      title: t('Project.card86.title'),
      date: t('Project.card86.date'),
      text: t('Project.card86.text'),
      id: 86,
    },
    {
      images: 'image-project/Открытые инновации.jpg',
      title: t('Project.card87.title'),
      date: t('Project.card87.date'),
      text: t('Project.card87.text'),
      id: 87,
    },
    {
      images:
        'image-project/Central Asia Dental Expo (CADEX) 2019  Казахстан.jpeg',
      title: t('Project.card88.title'),
      date: t('Project.card88.date'),
      text: t('Project.card88.text'),
      id: 88,
    },
    {
      images: 'image-project/АрмПродЭкспо 2019  Армения.jpg',
      title: t('Project.card89.title'),
      date: t('Project.card89.date'),
      text: t('Project.card89.text'),
      id: 89,
    },
    {
      images: 'image-project/Стоматология Беларуси.jpg',
      title: t('Project.card90.title'),
      date: t('Project.card90.date'),
      text: t('Project.card90.text'),
      id: 90,
    },
    {
      images: 'image-project/TTG-Incontri-1.jpg',
      title: t('Project.card91.title'),
      date: t('Project.card91.date'),
      text: t('Project.card91.text'),
      id: 91,
    },
    {
      images: 'image-project/Anuga-2019 Германия.jpg',
      title: t('Project.card92.title'),
      date: t('Project.card92.date'),
      text: t('Project.card92.text'),
      id: 92,
    },
    {
      images:
        'image-project/Петербургский международный газовый форум  Россия.jpg',
      title: t('Project.card93.title'),
      date: t('Project.card93.date'),
      text: t('Project.card93.text'),
      id: 93,
    },
    {
      images: 'image-project/Technical Fair Болгария.jpg',
      title: t('Project.card94.title'),
      date: t('Project.card94.date'),
      text: t('Project.card94.text'),
      id: 94,
    },
    {
      images: 'image-project/ПРОФСВАРКА Беларусь.jpg',
      title: t('Project.card95.title'),
      date: t('Project.card95.date'),
      text: t('Project.card95.text'),
      id: 95,
    },
    {
      images: 'image-project/stambul.jpg',
      title: t('Project.card96.title'),
      date: t('Project.card96.date'),
      text: t('Project.card96.text'),
      id: 96,
    },
    {
      images: 'image-project/1 (191).jpg',
      title: t('Project.card97.title'),
      date: t('Project.card97.date'),
      text: t('Project.card97.text'),
      id: 97,
    },
    {
      images: 'image-project/GRAINTECH.jpg',
      title: t('Project.card98.title'),
      date: t('Project.card98.date'),
      text: t('Project.card98.text'),
      id: 98,
    },
    {
      images: 'image-project/1 (207).jpg',
      title: t('Project.card99.title'),
      date: t('Project.card99.date'),
      text: t('Project.card99.text'),
      id: 99,
    },
    {
      images: 'image-project/1 (177).jpeg',
      title: t('Project.card100.title'),
      date: t('Project.card100.date'),
      text: t('Project.card100.text'),
      id: 100,
    },
    {
      images: 'image-project/1 (196).jpg',
      title: t('Project.card101.title'),
      date: t('Project.card101.date'),
      text: t('Project.card101.text'),
      id: 101,
    },
    {
      images: 'image-project/1 (182).JPG',
      title: t('Project.card102.title'),
      date: t('Project.card102.date'),
      text: t('Project.card102.text'),
      id: 102,
    },
    {
      images: 'image-project/1 (208).jpg',
      title: t('Project.card103.title'),
      date: t('Project.card103.date'),
      text: t('Project.card103.text'),
      id: 103,
    },
    {
      images: 'image-project/1 (179).jpg',
      title: t('Project.card104.title'),
      date: t('Project.card104.date'),
      text: t('Project.card104.text'),
      id: 104,
    },
    {
      images: 'image-project/1 (204).jpeg',
      title: t('Project.card105.title'),
      date: t('Project.card105.date'),
      text: t('Project.card105.text'),
      id: 105,
    },
    {
      images: 'image-project/1 (199).jpg',
      title: t('Project.card106.title'),
      date: t('Project.card106.date'),
      text: t('Project.card106.text'),
      id: 106,
    },
    {
      images: 'image-project/1 (206).jpg',
      title: t('Project.card107.title'),
      date: t('Project.card107.date'),
      text: t('Project.card107.text'),
      id: 107,
    },
    {
      images: 'image-project/1 (167).jpg',
      title: t('Project.card108.title'),
      date: t('Project.card108.date'),
      text: t('Project.card108.text'),
      id: 108,
    },
    {
      images: 'image-project/1 (170).jpg',
      title: t('Project.card109.title'),
      date: t('Project.card109.date'),
      text: t('Project.card109.text'),
      id: 109,
    },
    {
      images: 'image-project/1 (3).jpg',
      title: t('Project.card110.title'),
      date: t('Project.card110.date'),
      text: t('Project.card110.text'),
      id: 110,
    },
    {
      images: 'image-project/1 (5).jpg',
      title: t('Project.card111.title'),
      date: t('Project.card111.date'),
      text: t('Project.card111.text'),
      project: 'maksim',
      id: 111,
    },
    {
      images: 'image-project/1 (12).jpg',
      title: t('Project.card112.title'),
      date: t('Project.card112.date'),
      text: t('Project.card112.text'),
      project: 'intensive',
      id: 112,
    },
    {
      images: 'image-project/1 (14).jpg',
      title: t('Project.card113.title'),
      date: t('Project.card113.date'),
      text: t('Project.card113.text'),
      id: 113,
    },
    {
      images: 'image-project/1 (17).jpg',
      title: t('Project.card114.title'),
      date: t('Project.card114.date'),
      text: t('Project.card114.text'),
      id: 114,
    },
    {
      images: 'image-project/1 (23).jpg',
      title: t('Project.card115.title'),
      date: t('Project.card115.date'),
      text: t('Project.card115.text'),
      id: 115,
    },
    {
      images: 'image-project/1 (26).jpg',
      title: t('Project.card116.title'),
      date: t('Project.card116.date'),
      text: t('Project.card116.text'),
      project: 'regata',
      id: 116,
    },
    {
      images: 'image-project/1 (31).jpg',
      title: t('Project.card117.title'),
      date: t('Project.card117.date'),
      text: t('Project.card117.text'),
      project: 'guangzhou',
      id: 117,
    },
    {
      images: 'image-project/1 (33).jpg',
      title: t('Project.card118.title'),
      date: t('Project.card118.date'),
      text: t('Project.card118.text'),
      project: 'mission',
      id: 118,
    },
    {
      images: 'image-project/1 (38).jpg',
      title: t('Project.card119.title'),
      date: t('Project.card119.date'),
      text: t('Project.card119.text'),
      id: 119,
    },
    {
      images: 'image-project/1 (41).jpg',
      title: t('Project.card120.title'),
      date: t('Project.card120.date'),
      text: t('Project.card120.text'),
      id: 120,
    },
    {
      images: 'image-project/1 (47).jpg',
      title: t('Project.card121.title'),
      date: t('Project.card121.date'),
      text: t('Project.card121.text'),
      id: 121,
    },
    {
      images: 'image-project/1 (51).jpg',
      title: t('Project.card122.title'),
      date: t('Project.card122.date'),
      text: t('Project.card122.text'),
      id: 122,
    },
    {
      images: 'image-project/1 (56).jpg',
      title: t('Project.card123.title'),
      date: t('Project.card123.date'),
      text: t('Project.card123.text'),
      id: 123,
    },
    {
      images: 'image-project/1 (57).jpg',
      title: t('Project.card124.title'),
      date: t('Project.card124.date'),
      text: t('Project.card124.text'),
      id: 124,
    },
    {
      images: 'image-project/1 (63).jpg',
      title: t('Project.card125.title'),
      date: t('Project.card125.date'),
      text: t('Project.card125.text'),
      project: 'medconf',
      id: 125,
    },
    {
      images: 'image-project/1 (66).jpg',
      title: t('Project.card126.title'),
      date: t('Project.card126.date'),
      text: t('Project.card126.text'),
      id: 126,
    },
    {
      images: 'image-project/1 (70).jpg',
      title: t('Project.card127.title'),
      date: t('Project.card127.date'),
      text: t('Project.card127.text'),
      project: 'awards',
      id: 127,
    },
    {
      images: 'image-project/1 (73).jpg',
      title: t('Project.card128.title'),
      date: t('Project.card128.date'),
      text: t('Project.card128.text'),
      id: 128,
    },
    {
      images: 'image-project/1 (79).jpg',
      title: t('Project.card129.title'),
      date: t('Project.card129.date'),
      text: t('Project.card129.text'),
      id: 129,
    },
    {
      images: 'image-project/1 (81).jpg',
      title: t('Project.card130.title'),
      date: t('Project.card130.date'),
      text: t('Project.card130.text'),
      id: 130,
    },
    {
      images: 'image-project/1 (86).jpg',
      title: t('Project.card131.title'),
      date: t('Project.card131.date'),
      text: t('Project.card131.text'),
      id: 131,
    },
    {
      images: 'image-project/1 (90).jpg',
      title: t('Project.card132.title'),
      date: t('Project.card132.date'),
      text: t('Project.card132.text'),
      id: 132,
    },
    {
      images: 'image-project/1 (95).jpg',
      title: t('Project.card133.title'),
      date: t('Project.card133.date'),
      text: t('Project.card133.text'),
      id: 133,
    },
    {
      images: 'image-project/1 (100).jpg',
      title: t('Project.card134.title'),
      date: t('Project.card134.date'),
      text: t('Project.card134.text'),
      id: 134,
    },
    {
      images: 'image-project/1 (103).jpg',
      title: t('Project.card135.title'),
      date: t('Project.card135.date'),
      text: t('Project.card135.text'),
      id: 135,
    },
    {
      images: 'image-project/1 (107).jpg',
      title: t('Project.card136.title'),
      date: t('Project.card136.date'),
      text: t('Project.card136.text'),
      id: 136,
    },
    {
      images: 'image-project/1 (109).jpg',
      title: t('Project.card137.title'),
      date: t('Project.card137.date'),
      text: t('Project.card137.text'),
      id: 137,
    },
    {
      images: 'image-project/1 (116).jpg',
      title: t('Project.card138.title'),
      date: t('Project.card138.date'),
      text: t('Project.card138.text'),
      id: 138,
    },
    {
      images: 'image-project/1 (119).jpg',
      title: t('Project.card139.title'),
      date: t('Project.card139.date'),
      text: t('Project.card139.text'),
      id: 139,
    },
    {
      images: 'image-project/1 (121).jpg',
      title: t('Project.card140.title'),
      date: t('Project.card140.date'),
      text: t('Project.card140.text'),
      id: 140,
    },
    {
      images: 'image-project/1 (126).jpg',
      title: t('Project.card141.title'),
      date: t('Project.card141.date'),
      text: t('Project.card141.text'),
      id: 141,
    },
    {
      images: 'image-project/1 (132).jpg',
      title: t('Project.card142.title'),
      date: t('Project.card142.date'),
      text: t('Project.card142.text'),
      id: 142,
    },
    {
      images: 'image-project/1 (133).jpg',
      title: t('Project.card143.title'),
      date: t('Project.card143.date'),
      text: t('Project.card143.text'),
      id: 143,
    },
    {
      images: 'image-project/1 (137).jpg',
      title: t('Project.card144.title'),
      date: t('Project.card144.date'),
      text: t('Project.card144.text'),
      id: 144,
    },
    {
      images: 'image-project/1 (143).jpg',
      title: t('Project.card145.title'),
      date: t('Project.card145.date'),
      text: t('Project.card145.text'),
      id: 145,
    },
    {
      images: 'image-project/1 (147).jpg',
      title: t('Project.card146.title'),
      date: t('Project.card146.date'),
      text: t('Project.card146.text'),
      id: 146,
    },
    {
      images: 'image-project/1 (149).jpg',
      title: t('Project.card147.title'),
      date: t('Project.card147.date'),
      text: t('Project.card147.text'),
      id: 147,
    },
    {
      images: 'image-project/1 (155).jpg',
      title: t('Project.card148.title'),
      date: t('Project.card148.date'),
      text: t('Project.card148.text'),
      id: 148,
    },
    {
      images: 'image-project/1 (157).jpg',
      title: t('Project.card149.title'),
      date: t('Project.card149.date'),
      text: t('Project.card149.text'),
      id: 149,
    },
    {
      images: 'image-project/1 (161).jpg',
      title: t('Project.card150.title'),
      date: t('Project.card150.date'),
      text: t('Project.card150.text'),
      id: 150,
    },
  ];

  return (
    <div className="container">
      <div className="diplomas">
        <img className="img-fluid" src={diplomas} alt="" />
      </div>
      {project.map(({ id, title, date, text, images, project }) => (
        <ProjectsItem
          key={id}
          id={project}
          date={date}
          images={images}
          text={text}
          title={title}
        />
      ))}
    </div>
  );
};
